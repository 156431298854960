<template>
  <div class="flex flex-col methodology">
    <router-link
      :to="{
        name: 'search-view',
        query: {
          works: {
            refinementList: {
              methodologies: [methodology.name],
            },
          },
        },
      }"
    >
      <h2 class="h3-title">
        <span class="methodology-name">{{ methodology.name }}</span> ({{
          methodology.acronym
        }})
      </h2>
    </router-link>
    <p v-if="methodology.description" class="methodology-description">
      {{ methodology.description }}
    </p>
    <template
      v-if="
        methodology.zoteroPublications &&
        methodology.zoteroPublications.length > 0
      "
    >
      <h3 class="references h4-title">References</h3>
      <ol class="list-decimal ml-4 method-entry-container">
        <li
          class=""
          v-for="publication in methodology.zoteroPublications"
          :key="publication.id"
        >
          <div
            class="method-entry"
            v-html="publication.citation"
            :style="{ 'text-indent': '-2em', 'padding-left': '2em' }"
          ></div>
        </li>
      </ol>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    methodology: Object,
  },
};
</script>
